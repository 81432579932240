import axios from "axios"
import { toast } from "react-toastify"
import { IAbjPostpaidLookup,  IDSTVLookup,  IGOTVLookup,  IJosPostpaidLookup,   IKanoPostpaidLookup,   IPHEDLookup, IStartimesLookup } from "./lookupTypes"


export const lookupAirtime = async ( payload: Object  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/biller-lookup`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_TEST_URL}`,
          'publicKey': `${process.env.REACT_APP_PREPONE_BILLS_TEST_PUBLIC_KEY}`,
          'privateKey': `${process.env.REACT_APP_PREPONE_BILLS_TEST_PRIVATE_KEY}`,
          endpoint: "data/validate",
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )

        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message) 
    } 

}

export const lookupCableTV = async ( payload: Object  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/biller-lookup`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL_LIVE}`,
          'publicKey': `${process.env.REACT_APP_BILLERS_XPRESSPAY_LIVE_PUBLIC_KEY}`,
          'privateKey': `${process.env.REACT_APP_BILLERS_XPRESSPAY_LIVE_PRIVATE_KEY}`,
          endpoint: "cable/validate",
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )

        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message) 
    } 

}

export const lookupWAEC = async ( payload: Object  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/biller-lookup`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL_LIVE}`,
          'publicKey': `${process.env.REACT_APP_BILLERS_XPRESSPAY_LIVE_PUBLIC_KEY}`,
          'privateKey': `${process.env.REACT_APP_BILLERS_XPRESSPAY_LIVE_PRIVATE_KEY}`,
          endpoint: "education/waec/registration/validate",  
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )

        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message) 
    } 

}
export const lookupBetting = async ( payload: Object  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/biller-lookup`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL_LIVE}`,
          'publicKey': `${process.env.REACT_APP_BILLERS_XPRESSPAY_LIVE_PUBLIC_KEY}`,
          'privateKey': `${process.env.REACT_APP_BILLERS_XPRESSPAY_LIVE_PRIVATE_KEY}`,
          endpoint: "betting/validate",
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )

        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message) 
    } 

}

export const lookupJAMB = async ( payload: Object  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/biller-lookup`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL_LIVE}`,
          'publicKey': `${process.env.REACT_APP_BILLERS_XPRESSPAY_LIVE_PUBLIC_KEY}`,
          'privateKey': `${process.env.REACT_APP_BILLERS_XPRESSPAY_LIVE_PRIVATE_KEY}`,
          endpoint: "education/jamb/validate",
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )

        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message) 
    } 

}


export const lookupElectricity = async ( payload: {} ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/biller-lookup`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL_LIVE}`,
          'publicKey': `${process.env.REACT_APP_BILLERS_XPRESSPAY_LIVE_PUBLIC_KEY}`,
          'privateKey': `${process.env.REACT_APP_BILLERS_XPRESSPAY_LIVE_PRIVATE_KEY}`,
          endpoint: "electricity/validate",
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )

        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message) 
    } 

}


export const lookupAbjPostpaid = async ( payload: IAbjPostpaidLookup | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/lookup`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        const { data } = (res)

        if(!data){
            return
        } else {
            return data;
        }
     
    } catch (err: any) {
        return toast.error(err.message) 
    } 

}


export const lookupJosPostpaid = async ( payload: IJosPostpaidLookup | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/lookup`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        const { data } = (res)

        if(!data){
            return
        } else {
            return data;
        }
     
    } catch (err: any) {
        return toast.error(err.message) 
    } 

}

export const lookupKanoPostpaid = async ( payload: IKanoPostpaidLookup | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/lookup`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        const { data } = (res)

        if(!data){
            return
        } else {
            return data;
        }
     
    } catch (err: any) {
        return toast.error(err.message) 
    } 

}


export const lookupPHED = async ( payload: {} ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/biller-lookup`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_TEST_URL}`,
          'publicKey': `${process.env.REACT_APP_PREPONE_BILLS_TEST_PUBLIC_KEY}`,
          'privateKey': `${process.env.REACT_APP_PREPONE_BILLS_TEST_PRIVATE_KEY}`,
          endpoint: "electricity/validate",
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )

        return res.data;
     
    } catch (err: any) {
        return toast.error(err.message) 
    } 

}


export const lookupDSTV = async ( payload: IDSTVLookup | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/lookup`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        const { data } = (res)

        if(!data){
            return
        } else {
            return data;
        }
     
    } catch (err: any) {
        return toast.error(err.message) 
    } 

}


export const lookupGOTV = async ( payload: IGOTVLookup | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/lookup`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        const { data } = (res)

        if(!data){
            return
        } else {
            return data;
        }
     
    } catch (err: any) {
        return toast.error(err.message) 
    } 

}


export const lookupStartimes = async ( payload: IStartimesLookup | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/lookup`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        const { data } = (res)

        if(!data){
            return
        } else {
            return data;
        }
     
    } catch (err: any) {
        return toast.error(err.message) 
    } 

}


export const lookupSmileData = async ( payload: IStartimesLookup | undefined  ) => {
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/lookup`, 
        {
          'url': `${process.env.REACT_APP_BASE_API_URL}`,
          'authorization': `${process.env.REACT_APP_PREPONE_AUTHORIZATION}`,
          'webkey': `${process.env.REACT_APP_PREPONE_BILLS_WEBKEY}`,
          'accountid': `${process.env.REACT_APP_PREPONE_BILLS_ACCOUNTID}`,
          payload
        },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )
        
        const { data } = (res)

        if(!data){
            return
        } else {
            return data;
        }
     
    } catch (err: any) {
        return toast.error(err.message) 
    } 

}



export const validateFLWPayload = async ( payload: Object  ) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_PREPONE_BILLS_BASE_API_URL}/api/validate-bill-item`, 
            {
                token: `${process.env.REACT_APP_FLUTTERWAVE_LIVE_KEY}`,
                ...payload,
            },
        {headers: {  'Accept': 'application/json', 'Content-Type': 'application/json', }}, 
        )

        return res.data;
     
    } catch (err: any) {
        if (err.response) {

            // console.error('Error response:', err.response);
            return err.response.data || err.response.statusText;
          } else if (err.request) {

            // console.error('No response:', err.request);
            return 'No response received from the server.';
          } else {

            // console.error('Error message:', err.message);
            return err.message;
          }
    } 

}
