import React, { useEffect, useState } from "react";
import XPay from "npm_xpresspay";

import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../utils/TypeScript";
import Spinner from "../Alert/Spinner/Spinner";
import axios from "axios";
import { generateTransactionId, LiveBaseURL } from "../../utils/config";
import {
  initiateFlutterwavePayment,
  verifyFlutterwavePayment,
} from "../../utils/billers";

import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

export const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

const GovtLevySummaryComp = () => {
  const tranId = localStorage.getItem("tranId") || "";

  const query = useQuery();
  const status = query.get("status");
  const tx_ref = query.get("tx_ref");
  const transaction_id = query.get("transaction_id");

  const [state, setState] = useState({
    amount: "",
    loading: false,
    transactions: [],
  });

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const { currentCableTVPhoto } = useAppSelector((state) => state.cableTVPhoto);
  const { currentFIRSOrder } = useAppSelector((state) => state.firs);

  const totalAmt = Number(currentFIRSOrder?.amount) + 100;

  const paymentGateway = async () => {
    setLoading(true);

    const response = await initiateFlutterwavePayment({
      amount: totalAmt ?? 0,
      email: currentFIRSOrder?.userEmail ?? "",
      phoneNumber: currentFIRSOrder?.phoneNumber ?? "",
      serviceTitle: currentCableTVPhoto?.billerCode === "FIRS"
      ? "FIRS"
      : currentCableTVPhoto?.billerCode === "LCC"
      ? "LCC"
      : currentCableTVPhoto?.billerCode === "RIVS"
      ? "RVSG"
      : "null",
      redirectURL: window.location.href,
    });

    console.log({ response });

    if (response?.data?.link) {
      window.location.href = response.data.link;
      setLoading(false);
    } else {
      setLoading(false);
    }

    setLoading(false);
  };

  const verifyTransaction = async () => {
    setLoading(true);

    if (!transaction_id) {
      return setLoading(false);
    }

    const response = await verifyFlutterwavePayment({
      transactionId: transaction_id,
    });

    if (response?.status === "success") {
      toast.success("Payment Successful!");
      setLoading(false);
      localStorage.setItem("payment", "i");
      navigate("/govt-levy-done");
    } else {
      toast.error("Payment Failed!");
      setLoading(false);
    }
  };

  useEffect(() => {
    verifyTransaction();
  }, [status, transaction_id]);

  return (
    <div className="col-lg-9">
      <div className="row">
        <div className="col-lg-12">
          <div className="bg-light shadow-sm text-center mb-3">
            <div className="d-flex admin-heading pr-3">
              <div className="mr-auto">
                <h4
                  className="text-9 font-weight-400"
                  style={{ color: "#3f0d5e" }}
                >
                  <i className="fas fa-wallet mr-2"></i>
                  {
                  currentCableTVPhoto?.billerCode === "FIRS"
                    ? "FIRS PAYMENT"
                    : currentCableTVPhoto?.billerCode === "LCC"
                    ? "Lekki Concession Company"
                    : currentCableTVPhoto?.billerCode === "RIVS"
                    ? "Rivers State Govt"
                    : ""
                    }
                </h4>
              </div>
              <div className="ml-auto">
                <h4
                  className="text-9 font-weight-400"
                  style={{ color: "#3f0d5e" }}
                >
                  ₦{Number(totalAmt).toLocaleString() ?? 0}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 profile-content ">
        <ul className="nav nav-pills">
          <li className="nav-item">
            <Link className="nav-link" to="">
              Order
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link active" to="">
              Summary
            </Link>
          </li>
          {/* <li className="nav-item">
                                <Link className="nav-link" to="/recharge-payment">Payment</Link>
                            </li> */}
          <li className="nav-item">
            <Link className="nav-link" to="">
              Done
            </Link>
          </li>
        </ul>

        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade  show active"
            id="pills-contact"
            role="tabpanel"
            aria-labelledby="pills-contact-tab"
          >
            <div className="order-summery">
              <h3 className="title">Order Summary</h3>
              <div className="order-details-area table">
                <div className="bg-offwhite table-content">
                  <div className="row">
                    <div className="col">
                        {
                             currentCableTVPhoto?.billerCode === "FIRS"
                             ? "Tax Identification Number (TIN):"
                             : currentCableTVPhoto?.billerCode === "LCC"
                             ? "Vehicle Registration Number (VRN):"
                             : currentCableTVPhoto?.billerCode === "RIVS"
                             ? "State-issued Identification Number (SIN):"
                             : ""
                        }

                       
                        </div>

                    <div className="col text-right">
                      {currentFIRSOrder?.customer_id}
                    </div>
                  </div>
                </div>

                <div className=" table-content">
                  <div className="row">
                    <div className="col">Payment For:</div>
                    <div className="col text-right">
                      {currentFIRSOrder?.biller_name}
                    </div>
                  </div>
                </div>

                <div className="bg-offwhite table-content">
                  <div className="row">
                    <div className="col">Name</div>
                    <div className="col text-right">
                      {currentFIRSOrder?.userName}
                    </div>
                  </div>
                </div>

                <div className=" table-content">
                  <div className="row">
                    <div className="col">Email</div>
                    <div className="col text-right">
                      {currentFIRSOrder?.userEmail}
                    </div>
                  </div>
                </div>

                <div className="bg-offwhite table-content">
                  <div className="row">
                    <div className="col">Phone Number</div>
                    <div className="col text-right">
                      {currentFIRSOrder?.phoneNumber}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-5 ml-auto">
                    <div className="table-content">
                      <div className="row">
                        <div className="col">Amount</div>
                        <div className="col text-right">
                          ₦
                          {Number(currentFIRSOrder?.amount).toLocaleString() ??
                            0}
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col">Service Charge</div>
                        <div className="col text-right">₦100</div>
                      </div>
                      <hr />

                      <hr />
                      <div className=" row">
                        <div className="col font-weight-bold">Total</div>
                        <div className="col text-right font-weight-bold">
                          ₦{totalAmt.toLocaleString() ?? 0}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="row">
                                    <div className="col-md-6 text-left">
                                        <Link className="another-item-btn" to="/cabletv-order">+ Add another number Recharge</Link>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <Link className="coupon-btn" to="#">I have a promo code</Link>
                                    </div>
                                </div> */}
            {/* <br /> */}
            <div id="couponBox" className="coupon-box bg-offwhite p-3">
              <h3 className="title">Coupon Code</h3>
              <div className="input-group form-group mt-0">
                <input
                  className="form-control"
                  placeholder="Coupon Code"
                  type="text"
                />
                <span className="input-group-append">
                  <button className="btn btn-default" type="submit">
                    APPLY
                  </button>
                </span>
              </div>
            </div>

            <ul className="pager my-5">
              <li>
                <Link to="/cabletv-order" className="btn btn-default ">
                  <i className="fas fa-chevron-left"></i> Back
                </Link>
              </li>

              <li>
                <button
                  className="btn btn-default"
                  onClick={() => paymentGateway()}
                >
                  Next <i className="fas fa-chevron-right"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default GovtLevySummaryComp;
